<template>
  <div>
    <div class="pat-list" v-if="cardList.length > 0">
      <div
        class="jk_card"
        v-for="(item, index) in cardList"
        :key="index"
        @click="toShowaAllCard_jzk(item)"
      >
        <div class="card-face-container">
          <div class="card-top-info">
            <div class="default-jk" v-if="item.deafult == true">默认就诊卡</div>
            <div
              class="card-top-org"
              :class="{ default: item.deafult == true }"
            >
              河南省卫生健康委员会
            </div>
          </div>
          <div class="card-detail-info">
            <div class="card-user-info">
              <div class="card-user-name">{{ item.name }}</div>
              <div class="card-user-id">
                {{
                  item.idNumber.replace(
                    /^(.{4})(?:\d+)(.{4})$/,
                    "$1**********$2"
                  )
                }}
              </div>
            </div>
            <div class="card-qrcode">
              <img :src="qrImgSrc[index]" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pat-nocard" v-else>
      <div class="pat-nocard-text">
        <img
          src="../../assets/img/appoint/weitianjiajiuzhenren.png"
          width="100%"
          height="100%"
          alt=""
        />
      </div>
    </div>
    <van-button
      type="primary"
      class="add-pat"
      v-if="cardList.length < 5"
      @click="addPatient"
    >
      <van-icon name="plus" />
      添加就诊人
    </van-button>
    <van-button class="add-pat-disable" disabled v-else>
      <van-icon name="plus" />
      添加就诊人
    </van-button>
    <div class="add-card-tip">
      您还可以绑定<span>{{ 5 - cardList.length }}</span
      >张卡
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      cardList: [],
      qrImgSrc: [],
    };
  },
  created() {
    this.getCardList();
  },
  methods: {
    addPatient() {
      sessionStorage.setItem("backRoute", this.$route.fullPath);
      this.$router.push("/addPatient");
    },
    getCardList() {
      let postData = {
        userid: sessionStorage.getItem("userid"),
      };
      this.$http
        .get("/api/search/cards", postData)
        .then((res) => {
          if (res.status == 200) {
            this.cardList = res.data;
            this.cardList.forEach((item) => {
              let postData = {
                healthCardId: item.healthId,
                idType: item.idType,
                idNumber: item.idNumber,
                codeType: 1,
              };
              this.$healthHttpService
                .get("healthapi/api/search/img", postData)
                .then((result) => {
                  this.qrCodeText = res.data.qrCodeText;
                  this.qrImgSrc.push(
                    "data:image/png;base64," + result.data.qrCodeImg
                  );
                });
            });
          } else {
            Toast.fail({ message: "查询失败！" });
          }
        })
        .catch((err) => {
          Toast.fail({ message: err.response.data });
          console.log(err);
        });
    },
    toShowaAllCard_jzk(item) {
      sessionStorage.setItem("card_cardItem", JSON.stringify(item));
      this.$router.push({
        path: "/cardInformation",
      });
    },
  },
};
</script>

<style scoped>
.card-face-container {
  box-sizing: border-box;
  width: 9rem;
  height: 5rem;
  margin: 0.5rem auto;
  position: relative;
  color: #000000;
  background: url(../../assets/img/jiankangka.png);
  background-size: 100% 100%;
  border-radius: 10px;
}

.card-face-container .card-bg {
  height: 100%;
  width: 100%;
  position: absolute;
}

.card-face-container .card-top-info .card-top-org {
  font-size: 0.4rem;
  position: absolute;
  left: 10px;
  top: 15px;
}
.card-face-container .card-top-info .card-top-org.default {
  font-size: 0.4rem;
  position: absolute;
  left: 10px;
  top: 28px;
}

.card-face-container .card-detail-info {
  box-sizing: border-box;
  position: absolute;
  top: 3rem;
  padding: 0 0.2rem 0 0.5rem;
  display: flex;
  flex-flow: row;
  width: 100%;
  font-size: 0.4rem;
  text-align: left;
}

.card-face-container .card-detail-info .card-qrcode {
  padding: 0.1rem;
  position: absolute;
  right: 0.5rem;
  top: -1rem;
  height: 2rem;
  width: 2rem;
  box-sizing: content-box;
}
.qrcode-img {
  height: 2rem;
  width: 2rem;
  position: relative;
}

.card-face-container .card-detail-info .card-qrcode img {
  height: 2rem;
  width: 2rem;
}
.card-face-container .default-jk {
  font-size: 0.3rem;
  padding: 0.15rem;
  float: left;
  color: #ffffff;
  background-color: #febc06;
  margin-bottom: 0.1rem;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
}

.pat-nocard {
  margin-top: 45%;
  margin-bottom: 1.5rem;
  font-size: 0.5rem;
}

.add-pat {
  width: 9rem;
  border-radius: 5px;
  border: none;
  background-image: linear-gradient(to right, #59fdc5, #00d48b);
  margin-bottom: 0.5rem;
  font-size: 0.4rem;
}

.add-pat .van-icon {
  position: relative;
  top: 2px;
  left: -2px;
}

.add-pat-disable {
  width: 9rem;
  border-radius: 5px;
  border: none;
  background-color: #c9c9c9;
  color: #ffffff;
  margin-bottom: 0.5rem;
}

.add-pat-disable .van-icon {
  position: relative;
  top: 2px;
  left: -2px;
}
.add-card-tip {
  font-size: 0.4rem;
  color: #a2a2a2;
  margin-bottom: 0.5rem;
}
.add-card-tip span {
  color: #1bdc9b;
}
</style>
